<template>
    <v-container fluid>
        <v-card flat light>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-subheader class="headline">{{ $t('administrator_activity') }}</v-subheader>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-tabs v-if="$route.name !== 'administrator.create'"
                                :right="$vuetify.breakpoint.mdAndUp"
                                :grow="$vuetify.breakpoint.smAndDown"
                                color="indigo accent-2"
                                background-color="transparent">
                            <v-tab v-if="$route.params.id" link :to="{name: 'administrator.activity', params:{id: $route.params.id}}">
                                {{ $t('activity') }}
                            </v-tab>
                            <v-tab v-if="$route.params.id" link :to="{name: 'administrator.edit', params:{id: $route.params.id}}">
                                {{ $t('profile') }}
                            </v-tab>
                            <v-tab v-if="!$route.params.id" link :to="{name: 'profile.activity'}">
                                {{ $t('activity') }}
                            </v-tab>
                            <v-tab v-if="!$route.params.id" link :to="{name: 'profile'}">
                                {{ $t('profile') }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="activityItems" :options.sync="options" :page.sync="page"
                                      :items-per-page="perPage" :server-items-length="totalActivities"
                                      :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                      @page-count="pageCount = options.pageCount = Number($event)"
                                      :loading-text="$t('loading_please_wait')" class="elevation-1"
                                      hide-default-footer>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex"

    export default {
        name: "AdministratorActivities",
        data() {
            return {
                activityItems: [],
                sortBy: "created_at",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalActivities: 0,
                options: {},
                loading: false,
                headers: [
                    {
                        text: this.$t('type_activity'),
                        align: "left",
                        sortable: true,
                        value: "type"
                    },
                    {
                        text: this.$t('device_name'),
                        sortable: true,
                        value: "device"
                    },
                    {
                        text: this.$t('ip_address'),
                        sortable: true,
                        value: "ip"
                    },
                    {
                        text: this.$t('date_and_time'),
                        align: "center",
                        sortable: true,
                        value: "created_at",
                    }
                ]
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getActivities()
                },
                deep: false
            }
        },
        methods: {
            async getActivities(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0]
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc'
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage
                }
                if (this.$route.params.id ) {
                    params.admin = this.$route.params.id
                } else {
                    params.admin = this.$auth.user().id
                }


                await this.$http
                    .get("admin/admin_auth_status", {
                        params: params,
                    })
                    .then(res => {
                        this.activityItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalActivities = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.activityItems = []
                        this.totalActivities = 0
                        this.$toastr.error(this.$t('failed_to_get_list_administrator_activities'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
</script>
